<script>
import './select2-user.scss'
export default {
  name: 'select2-user',
  props: {
    name: {
      type: String,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    multiple: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Type or select user'
    },
    queryParameters: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.configureSelect2(this.fieldId, this.placeholder)
    this.loadSelectedClientsIntoSelect2(this.queryParameters, this.fieldId)
  },
  methods: {
    configureSelect2 (id, placeholder) {
      $(`#${id}`).select2({
        ajax: {
          url: '/users',
          dataType: 'json',
          delay: 250,
          data: this.processData,
          processResults: this.processResults,
          cache: true
        },
        placeholder,
        allowClear: true
      })
    },
    processResults (data) {
      const users = data && JSON.parse(data.users).map(function (r) {
        return {
          id: r.id,
          text: r.full_name
        }
      })

      return {
        results: users
      }
    },
    processData (params) {
      return { q: params.term }
    },
    loadSelectedClientsIntoSelect2 (queryParameters, id) {
      const selectedValues = JSON.parse(queryParameters).users_ids
      let data = []

      $.ajax({
        url: '/users',
        dataType: 'json',
        data: { users_ids: selectedValues }
      }).done(function (response) {
        data = response.constructor === Array && response.map(user => (
          {
            id: user.id,
            text: user.title
          }
        ))

        data.constructor === Array && data.forEach(user => {
          const newOption = new Option(user.text, user.id, false, false)
          $(`#${id}`).append(newOption).trigger('change')
        })
        $(`#${id}`).val(selectedValues).change()
      })
    }
  }
}
</script>
<template>
  <select
    :id="fieldId"
    class="select2 recipient-input"
    :name="name"
    :multiple="multiple"
    style="width: 100%"
  />
</template>
