export function createDashboardDomElement (dasboardId) {
  const el = document.createElement('div')
  el.setAttribute('id', dasboardId)
  document.body.prepend(el)
}

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export function validateArrayLength (arr) {
  const isArr = Array.isArray(arr)

  if (!isArr) {
    console.warn('It\'s not an array')
    return false
  }

  if (isArr) return arr && arr.length
}

export function select2HasValue (data) {
  return data && data.length > 0
};

export function resetSelect2 (target) {
  try {
    target.empty().trigger('change')
  } catch (err) {}
}

export function cleanPhoneInput (number) {
  return number && number.replace(/[^0-9.]/g, '')
}

export function dynamicConfig ({ placeholder, allowClear, removeCreateNewContact = false, dropdownParent }) {
  const configObj = {
    ajax: {
      url: '/contacts',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          q: params.term,
          page: params.page
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1
        const filteredContacts = data.items.filter(function (contact) { return contact.id !== 'Create a new Contact +' })
        const results = removeCreateNewContact ? filteredContacts : data.items

        return {
          results,
          pagination: {
            more: params.page * 10 < data.total_count
          }
        }
      },
      cache: true
    },
    allowClear,
    dropdownParent,
    placeholder,
    language: {
      noResults: function () {
        return '<span>The recipient doesn’t exist yet.</span>'
      }
    },
    escapeMarkup: function (markup) {
      return markup
    }
  }

  return configObj
}

export function smsCounterBubble ({ number }) {
  const smsUnseenBubble = $('#btn-open-user-text-messaging .notifications-count')

  if (number > 0) {
    smsUnseenBubble.removeClass('hide')
    smsUnseenBubble.text(number)
  } else if (number === 0) {
    smsUnseenBubble.addClass('hide')
    smsUnseenBubble.text('')
  }
}

const utils = {
  createDashboardDomElement,
  uuidv4,
  validateArrayLength,
  select2HasValue,
  resetSelect2,
  cleanPhoneInput,
  dynamicConfig,
  smsCounterBubble
}

export default utils
